import React from "react"
import Layout from "../components/layout"
import {withPrefix} from "gatsby";
import PreLoader from "../components/preLoader";
import MainMenu from "../components/mainMenu";
import $ from "jquery";
import AOS from "aos";

class Features extends React.Component {

    // componentDidMount is the right place
    async componentDidMount() {

        // init popover
        $(() => {
            if ($("[data-aos]").length) {
                AOS.init({
                    duration: 1000,
                    mirror: true
                });
            }

        });

    }

    render() {
        return (<Layout pageTitle="Training Centre Manager">


                <div className="fancy-hero-three">
                    <div className="shapes shape-one"></div>
                    <div className="shapes shape-two"></div>
                    <div className="shapes shape-three"></div>
                    <div className="shapes shape-four"></div>
                    <div className="shapes shape-five"></div>
                    <div className="shapes shape-six"></div>
                    <div className="bg-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-9 col-lg-10 m-auto">
                                    <h1 className="heading">Check our features with details.</h1>
                                    <p className="sub-heading">deski helps teams of all sizes get better at delivering
                                        effortless customer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fancy-text-block-eleven mt-225 md-mt-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 order-lg-last">
                                <div className="text-wrapper">
                                    <div className="title-style-two mb-35 md-mb-20">
                                        <h2><span>Exclusive feature <img src="images/shape/line-shape-5.svg"
                                                                         alt=""/></span>
                                            with great offers everytime.
                                        </h2>
                                    </div>
                                    <p>With Benefits from deski Pro, Earn rewards & Score discounts on purchases*
                                        Foryourself and your customers.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-first">
                                <div className="img-gallery">
                                    <img src="images/media/img_35.png" alt=""/>
                                    <img src="images/media/img_36.png" alt="" className="overlay-img"/>
                                    <img src="images/shape/49.svg" alt="" className="shapes shape-one"/>
                                    <img src="images/shape/50.svg" alt="" className="shapes shape-two"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="fancy-feature-six mt-140 md-mt-70">
                    <div className="bg-wrapper">
                        <div className="shapes shape-one"></div>
                        <div className="shapes shape-two"></div>
                        <div className="shapes shape-three"></div>
                        <div className="shapes shape-four"></div>
                        <div className="shapes shape-five"></div>
                        <img src="images/shape/51.svg" alt="" className="shapes shape-six"/>
                        <div className="container">
                            <div className="title-style-two text-center mb-85 md-mb-40">
                                <h2>Our <span>Features <img src="images/shape/line-shape-2.svg" alt=""/></span>
                                    list
                                </h2>
                                <div className="sub-text mt-15">Get to know all deski features, that are part of the
                                    complex multi-channel
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="block-style-five">
                                        <div className="icon"><img src="images/icon/20.svg" alt=""/></div>
                                        <h6 className="title"><span>Smart popups</span></h6>
                                        <p>Create customized popups and show the right message at the right time.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1200"
                                     data-aos-delay="100">
                                    <div className="block-style-five">
                                        <div className="icon"><img src="images/icon/21.svg" alt=""/></div>
                                        <h6 className="title"><span>Embeded Forms</span></h6>
                                        <p>Collect website leads with embedded forms and integrate easily.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="block-style-five">
                                        <div className="icon"><img src="images/icon/22.svg" alt=""/></div>
                                        <h6 className="title"><span>Autoresponder</span></h6>
                                        <p>Send welcome email to your new subscribers with a code.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1200"
                                     data-aos-delay="100">
                                    <div className="block-style-five">
                                        <div className="icon"><img src="images/icon/21.svg" alt=""/></div>
                                        <h6 className="title"><span>Embeded Forms</span></h6>
                                        <p>Collect website leads with embedded forms and integrate easily.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1200">
                                    <div className="block-style-five">
                                        <div className="icon"><img src="images/icon/22.svg" alt=""/></div>
                                        <h6 className="title"><span>Autoresponder</span></h6>
                                        <p>Send welcome email to your new subscribers with a code.</p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex" data-aos="fade-up" data-aos-duration="1200"
                                     data-aos-delay="100">
                                    <div className="block-style-five">
                                        <div className="icon"><img src="images/icon/20.svg" alt=""/></div>
                                        <h6 className="title"><span>Smart popups</span></h6>
                                        <p>Create customized popups and show the right message at the right time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Features;